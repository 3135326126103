.ad-banner {
    display: flex;
    align-items: center;
    background-color: #ffffff; /* 普通广告的背景色 */
    height: 40px;
    overflow: hidden;
    position: relative;
    padding: 0 10px;
  }
  
  .ad-banner-link {
    background-color: #e6f7ff; /* 链接广告的背景色 */
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: 2;
  }
  
  .marquee-container {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }
  
  .marquee-container::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    background: linear-gradient(to right, #ffffff, transparent);
    z-index: 1;
  }
  
  .ad-banner-link .marquee-container::before {
    background: linear-gradient(to right, #e6f7ff, transparent);
  }
  
  .marquee {
    display: inline-block;
    white-space: nowrap;
    padding-left: 100%;
    animation: marquee 20s linear infinite;
  }
  
  .marquee a, .marquee span {
    color: #333333; /* 文字颜色 */
    text-decoration: none;
  }
  
  .marquee a:hover {
    text-decoration: underline;
  }
  
  @keyframes marquee {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
  }