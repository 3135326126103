.collapsible-cart {
  position: fixed;
  top: 0;
  right: -80%; /* 改为 -80% */
  width: 80%; /* 宽度改为 80% */
  height: 100vh;
  background-color: white;
  overflow-y: auto;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  padding: 20px;
}

.collapsible-cart.visible {
  right: 0;
}

/* 添加一个半透明背景 */
.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.cart-overlay.visible {
  display: block;
}

.toggle-cart {
  position: fixed;
  top: 50%;
  left: calc(100% - 40px);
  width: 40px;
  height: 80px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: left 0.3s ease-in-out;
  z-index: 1001;
}

.collapsible-cart.visible .toggle-cart {
  left: calc(100% - 360px);
}

.cart-content {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
}

.floating-cart-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

/* 媒体查询 */
@media (max-width: 1024px) {
  .collapsible-cart {
    width: 280px;
    right: -280px;
  }

  .collapsible-cart.visible .toggle-cart {
    left: calc(100% - 320px);
  }
}

@media (max-width: 768px) {
  .collapsible-cart {
    width: 100%;
    right: -100%;
    max-width: 400px;
  }

  .collapsible-cart.visible {
    right: 0;
  }

  .toggle-cart {
    top: 50%;
    left: calc(100% - 40px);
    width: 40px;
    height: 80px;
  }

  .collapsible-cart.visible .toggle-cart {
    left: calc(100% - 40px);
  }

  .floating-cart-icon {
    display: flex;
  }
}

@media (max-width: 480px) {
  .collapsible-cart {
    max-width: 100%;
  }

  .toggle-cart {
    width: 30px;
    height: 60px;
    font-size: 16px;
  }
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
  position: relative;
}

.cart-item-image {
  width: 60px;  /* 调整图片大小 */
  height: 60px; /* 调整图片大小 */
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px; /* 添加圆角 */
}

.cart-item-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cart-item-details h3 {
  margin: 0 0 5px 0;
  font-size: 14px; /* 减小字体大小 */
}

.cart-item-details p {
  margin: 0 0 5px 0;
  font-size: 12px; /* 减小字体大小 */
}

.cart-item-stock {
  margin-right: 10px;
  color: #666;
  font-size: 0.9em;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.quantity-control button {
  width: 25px; /* 减小按钮大小 */
  height: 25px; /* 减小按钮大小 */
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  font-size: 12px; /* 减小字体大小 */
}

.quantity-control button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity-control span {
  margin: 0 10px;
}

.remove-item-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px; /* 调整按钮大小 */
  height: 25px; /* 调整按钮大小 */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px; /* 调整字体大小 */
  margin-left: 10px; /* 添加左边距 */
}

.contact-info, .delivery-options, .additional-info {
  margin-top: 20px;
}

.contact-info label {
  display: block;
  margin-bottom: 5px;
}

.contact-info input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.delivery-options h3 {
  margin-bottom: 10px;
}

.option {
  margin-bottom: 10px;
}

.option input[type="radio"] {
  margin-right: 10px;
}

.additional-info {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
}

.additional-info p {
  font-weight: bold;
  margin-bottom: 10px;
}

.additional-info ul {
  padding-left: 20px;
  margin: 0;
}

.cart-total {
  margin-top: 20px;
  text-align: right;
}

.checkout-btn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.checkout-btn:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .cart-item {
    flex-direction: row; /* 改回行布局 */
    align-items: center;
    padding: 10px;
  }

  .cart-item-image {
    width: 40px; /* 进一步减小手机版图片大小 */
    height: 40px;
    margin-right: 10px;
  }

  .cart-item-details {
    flex: 1;
  }

  .remove-item-btn {
    margin-left: 5px;
  }

  .quantity-control button {
    width: 25px;
    height: 25px;
  }
}

.checkout-button-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.checkout-button-container:hover .tooltip {
  opacity: 1;
}

@media (max-width: 768px) {
  .tooltip {
    bottom: auto;
    top: 100%;
    margin-top: 5px;
  }
}

/* 其他现有样式保持不变 */


/* 在 CollapsibleCart.css 文件中添加以下样式 */

.delivery-options .option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.delivery-options .option input[type="checkbox"] {
  display: none;
}

.delivery-options .option label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  line-height: 25px;
  display: inline-block;
  color: #333;
}

.delivery-options .option label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 2px solid #ddd;
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.delivery-options .option input[type="checkbox"]:checked + label:before {
  background: #4CAF50;
  border-color: #4CAF50;
}

.delivery-options .option label:after {
  content: '\2714';
  position: absolute;
  top: -1px;
  left: 6px;
  font-size: 20px;
  color: #fff;
  transition: all 0.2s;
  opacity: 0;
  transform: scale(0);
}

.delivery-options .option input[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.delivery-options .option:hover label:before {
  border-color: #4CAF50;
}

/* 在 CollapsibleCart.css 文件中添加以下样式 */

.additional-info {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.additional-info p {
  font-size: 14px;
  font-weight: bold;
  color: #a72929;
  margin-bottom: 10px;
}

.additional-info ul {
  padding-left: 20px;
  margin: 0;
}

.additional-info li {
  font-size: 12px;
  color: #ab3512;
  line-height: 1.6;
  margin-bottom: 5px;
}

/* 在 CollapsibleCart.css 文件中添加以下样式 */

.telegram-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.telegram-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.telegram-link {
  color: #0088cc;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.telegram-link:hover {
  color: #005580;
}