.image-carousel {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.carousel-slide {
  position: relative;
  width: 100%;
  padding-top: 33.33%; /* 3:1 比例 */
}

.carousel-slide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .image-carousel {
    margin-bottom: 0; /* 移除可能的底部边距 */
  }

  .carousel-slide {
    padding-top: 50%; /* 在移动端使用 2:1 比例 */
  }

  /* 确保图片填满容器 */
  .carousel-slide img {
    object-fit: cover;
  }
}

/* 移除 Slick 轮播图可能添加的额外边距 */
.slick-slider {
  margin-bottom: 0 !important;
}

.slick-list {
  margin-bottom: 0 !important;
}
