.homepage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.carousel-container {
  width: 100%;
  height: 400px; /* 根据需要调整高度 */
  overflow: hidden;
}

.ad-banner-container {
  width: 100%;
  padding: 10px 0;
  background-color: #f0f0f0; /* 可以根据需要调整背景色 */
}

.content-container {
  flex: 1;
  padding: 20px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

/* 确保广告横幅不被遮挡 */
.ad-banner {
  z-index: 10;
  position: relative;
  transition: all 0.3s ease;
}

.ad-banner:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.product-info {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.product-info h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
}

.product-info p {
  margin: 0 0 10px 0;
  color: #666;
}

.price {
  font-weight: bold;
  color: #3498db;
  margin-bottom: 10px;
}

.product-info button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.product-info button:hover {
  background-color: #2980b9;
}

.close-cart {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.animate-marquee {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
  white-space: nowrap;
}

.animate-marquee:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.homepage header {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0;
  overflow: hidden;
}

.icon-container {
  width: 50px;
  background-color: #3b82f6;
}

.marquee-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.animate-marquee span {
  display: inline-block;
  padding-right: 100%;
  white-space: nowrap;
}

.ad-link {
  color: #d412c0;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
}

.ad-link:hover {
  text-decoration: underline;
}

.animate-marquee a, .animate-marquee span {
  display: inline-block;
  padding-right: 100%;
  white-space: nowrap;
}

.search-container {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f8f8;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.search-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
}

.cart-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  padding: 5px;
}

.cart-item-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
}

.category-nav-container {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 60px; /* 调整这个值以适应搜索栏的高度 */
  z-index: 999;
}

.category-nav {
  flex-grow: 1;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* 对 Firefox */
  -ms-overflow-style: none; /* 对 IE 和 Edge */
}

.category-nav::-webkit-scrollbar {
  display: none; /* 对 Chrome, Safari, 和 Opera */
}

.category-nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.category-nav li {
  display: inline-block;
  margin-right: 10px;
}

.category-nav button,
.all-categories button {
  background: none;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 15px;
  transition: background-color 0.3s, color 0.3s;
}

.category-nav button.active,
.all-categories button.active {
  background-color: #3498db;
  color: white;
}

.category-expand-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-left: 10px;
}

.all-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #eee;
}

/* 移动端样式 */
@media (max-width: 768px) {
  .homepage {
    padding: 10px;
  }

  .content-container {
    padding: 10px;
  }

  .carousel-container {
    height: auto; /* 调整轮播图高度适应内容 */
    margin-bottom: 0; /* 确保轮播图下方没有多余的空间 */
  }

  .products-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }

  .product-card {
    font-size: 14px;
  }

  .product-image {
    height: 150px;
  }

  .product-info h3 {
    font-size: 16px;
  }

  .collapsible-cart {
    position: fixed;
    top: 0;
    right: -85%;
    width: 85%;
    height: 100vh;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
  }

  .collapsible-cart.visible {
    right: 0;
  }

  .cart-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
  }

  .cart-overlay.visible {
    display: block;
  }

  .category-nav-container {
    padding: 10px;
  }

  .category-nav button,
  .all-categories button {
    font-size: 12px;
    padding: 5px 8px;
  }
}
