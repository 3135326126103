.payment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.payment-modal {
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  position: relative;
}

.payment-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f8ff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.payment-amount, .payment-timer {
  font-size: 16px;
  color: #333;
}

.payment-amount span, .payment-timer span {
  font-weight: bold;
  color: #2980b9;
}

.payment-timer span {
  color: #e74c3c;
}

.payment-button {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: #2980b9;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.payment-button:hover {
  background-color: #216897;
}

.close-modal-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-modal-button:hover {
  color: #333;
}

.payment-modal h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 480px) {
  .payment-modal {
      padding: 20px;
      border-radius: 15px;
  }

  .payment-info {
      flex-direction: column;
      align-items: flex-start;
  }

  .payment-amount, .payment-timer {
      width: 100%;
      margin-bottom: 10px;
  }

  .payment-button {
      font-size: 16px;
      padding: 12px;
  }

  .close-modal-button {
      font-size: 22px;
  }
  
  
  
  
#top-level-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    pointer-events: none;
}

#top-level-container > * {
    pointer-events: auto;
}

.payment-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.payment-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 1001;
}

/* 这个样式可能需要根据实际情况调整 */
#top-level-container .external-popup {
    z-index: 10000 !important;
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}


}
